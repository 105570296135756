<template>
  <v-container
    id="employees"
    fluid
    tag="section"
    class="container-full-size"
  >
    <v-data-table
      :headers="headers"
      :items="employees"
      :search="search"
      :no-data-text="str['employees_empty']"
      :no-results-text="str['employees_empty']"
      class="elevation-1"
      @click:row="editEmployee"
    >
      <template v-slot:top>
        <v-toolbar
          flat
        >
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            :label="str['search']"
            single-line
            hide-details
          />
          <v-spacer />
          <v-btn
            v-if="historicAvailable"
            color="blue"
            dark
            class="mb-2"
            @click="showHistoric()"
            style="margin-right: 10px;"
          >
            {{ str['general_historic'] }}
          </v-btn>
          <v-btn
            v-if="historicAvailable"
            color="secondary"
            dark
            class="mb-2"
            @click="openSearchClientLogsDialog()"
            style="margin-right: 10px;"
          >
            {{ str['search_by_client'] }}
          </v-btn>
          <v-dialog
            v-model="dialogEmployee"
            persistent
            max-width="750px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-if="editPermission && !isEmployee"
                color="success"
                dark
                class="mb-2"
                v-bind="attrs"
                v-on="on"
                @click="openNewEmployee()"
              >
                {{ str['add'] }}
              </v-btn>
            </template>
            <v-card>
              <v-card-title>
                <span class="headline">{{ formTitle }}</span>
              </v-card-title>
              <v-card-text>
                <v-container>
                  <v-row v-if="showEmployeeTabs">
                    <v-col
                      cols="12"
                      class="pb-1"
                    >
                      <v-tabs
                        v-model="employeeTab"
                        background-color="transparent"
                        color="secondary"
                        style="margin-top: 0;"
                        grow
                        show-arrows
                      >
                        <v-tab
                          v-for="tab in employeeTabs"
                          :key="tab.value"
                        >
                          {{ str[tab.label] ? str[tab.label] : tab.label }}
                        </v-tab>
                      </v-tabs>
                    </v-col>
                  </v-row>
                  <v-row
                    v-if="!showEmployeeTabs || (showEmployeeTabs && employeeTabs[employeeTab].value !== 'schedule')"
                    class="custom-scroll modal-height-fixed"
                  >
                    <v-col
                      v-if="showEmployeeTabs && employeeTabs[employeeTab].value === 'permissions'"
                      class="pb-0"
                    >
                      <v-btn
                        color="secondary"
                        @click="selectAllPermissions(true)"
                        class="mr-2"
                      >
                        {{ str['select_all'] }}
                      </v-btn>
                      <v-btn
                        color="primary"
                        @click="selectAllPermissions(false)"
                      >
                        {{ str['deselect_all'] }}
                      </v-btn>
                      <v-btn
                        v-if="employees.length"
                        color="success"
                        @click="usePermissions()"
                        class="ml-2"
                      >
                        {{ str['use_permissions'] }}
                      </v-btn>
                    </v-col>
                    <v-col
                      v-for="item in employeeData"
                      :key="item.id"
                      cols="12"
                      v-if="!showEmployeeTabs || (showEmployeeTabs && ((employeeTabs[employeeTab].value === 'details' && !item.isPermission) || (employeeTabs[employeeTab].value === 'permissions' && item.isPermission)))"
                    >
                      <h3
                        v-if="item.parentTitle"
                        class="mb-2"
                      >
                        {{ str[item.parentTitle] ? str[item.parentTitle] : item.parentTitle }}
                      </h3>
                      <div
                        v-if="!showEmployeeTabs && item.isPermission && item.parentTitle"
                        class="pb-2"
                      >
                        <v-btn
                          color="secondary"
                          @click="selectAllPermissions(true)"
                          class="mr-2"
                        >
                          {{ str['select_all'] }}
                        </v-btn>
                        <v-btn
                          color="primary"
                          @click="selectAllPermissions(false)"
                        >
                          {{ str['deselect_all'] }}
                        </v-btn>
                        <v-btn
                          v-if="employees.length"
                          color="success"
                          @click="usePermissions()"
                          class="ml-2"
                        >
                          {{ str['use_permissions'] }}
                        </v-btn>
                      </div>
                      <h5
                        v-if="item.parentSubtitle"
                        class="mb-2"
                      >
                        {{ str[item.parentSubtitle] ? str[item.parentSubtitle] : item.parentSubtitle }}
                      </h5>
                      <div class="row-flex-align">
                        <v-text-field
                          v-if="item.type === 'input'"
                          v-model="item.value"
                          :label="item.title"
                          :disabled="item.disabled ? true : false"
                          hide-details
                        />
                        <v-autocomplete
                          v-if="item.type === 'select'"
                          v-model="item.value"
                          :label="item.title"
                          item-text="label"
                          item-value="value"
                          :items="item.items"
                          :no-data-text="str['no_data']"
                          hide-details
                        />
                        <v-checkbox
                          v-if="item.type === 'checkbox'"
                          v-model="item.value"
                          :label="item.title"
                          class="form-field-checkbox"
                          hide-details
                        />
                        <v-menu
                          v-if="item.type === 'datepicker'"
                          v-model="datepickerMenu"
                          :close-on-content-click="false"
                          :nudge-right="40"
                          transition="scale-transition"
                          offset-y
                          min-width="290px"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                              v-model="item.value"
                              v-bind:label="item.title"
                              readonly
                              v-bind="attrs"
                              v-on="on"
                              hide-details
                            />
                          </template>
                          <v-date-picker
                            v-model="item.value"
                            style="margin: 0;"
                            @change="datepickerMenu = false"
                            :locale="datepickerLanguage"
                          />
                        </v-menu>
                        <v-tooltip 
                          v-if="item.info"
                          bottom
                          color="secondary"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-icon
                              color="secondary"
                              dark
                              v-bind="attrs"
                              v-on="on"
                              class="cursor-hover ml-2"
                            >
                              mdi-information
                            </v-icon>
                          </template>
                          <span>
                            {{ item.info }}
                          </span>
                        </v-tooltip>
                      </div>
                    </v-col>
                  </v-row>
                  <v-row
                    v-if="showEmployeeTabs && employeeTabs[employeeTab].value === 'schedule'"
                    class="custom-scroll modal-height-fixed"
                  >
                    <v-col
                      v-if="scheduleData"
                      cols="12"
                    >
                      <v-data-table
                        :headers="scheduleHeaders"
                        :items="scheduleData"
                        :no-data-text="str['no_data']"
                        :no-results-text="str['no_data']"
                        :hide-default-footer="true"
                        class="elevation-1"
                        style="width: 100%;"
                      >
                        <template v-slot:item.weekday="{ item }">
                          {{ scheduleDaysDict[item.weekday] ? scheduleDaysDict[item.weekday].label : str['n_a'] }}
                        </template>
                        <template v-slot:item.start_time="{ item }">
                          <div
                            style="display: flex; justify-content: center; align-items: center;"
                            v-bind:class="{'custom-disabled': scheduleStatusDict[item.status].disable_times}"
                          >
                            <v-select
                              v-model="item.start_time_h"
                              item-text="label"
                              item-value="value"
                              :items="hours"
                              hide-details
                              style="max-width: 50px; margin: 0 auto; padding: 0;"
                              :no-data-text="str['no_data']"
                            />
                            <span style="margin: 0 5px;">
                              :
                            </span>
                            <v-select
                              v-model="item.start_time_m"
                              item-text="label"
                              item-value="value"
                              :items="minutes"
                              hide-details
                              style="max-width: 50px; margin: 0 auto; padding: 0;"
                              :no-data-text="str['no_data']"
                            />
                          </div>
                        </template>
                        <template v-slot:item.end_time="{ item }">
                          <div
                            style="display: flex; justify-content: center; align-items: center;"
                            v-bind:class="{'custom-disabled': scheduleStatusDict[item.status].disable_times}"
                          >
                            <v-select
                              v-model="item.end_time_h"
                              item-text="label"
                              item-value="value"
                              :items="hours"
                              hide-details
                              style="max-width: 50px; margin: 0 auto; padding: 0;"
                              :no-data-text="str['no_data']"
                            />
                            <span style="margin: 0 5px;">
                              :
                            </span>
                            <v-select
                              v-model="item.end_time_m"
                              item-text="label"
                              item-value="value"
                              :items="minutes"
                              hide-details
                              style="max-width: 50px; margin: 0 auto; padding: 0;"
                              :no-data-text="str['no_data']"
                            />
                          </div>
                        </template>
                        <template v-slot:item.status="{ item }">
                          <v-select
                            v-model="item.status"
                            item-text="label"
                            item-value="value"
                            :items="scheduleStatusItems"
                            hide-details
                            style="max-width: 120px; margin: 0 auto; padding: 0;"
                            :no-data-text="str['no_data']"
                          />
                        </template>
                        <template v-slot:footer.page-text="{ pageStart, pageStop, itemsLength }">
                          {{ pageStart }}-{{ pageStop }} {{ str['of'] }} {{ itemsLength }}
                        </template>
                      </v-data-table>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
              <v-card-actions>
                <v-spacer />
                <v-btn
                  color="default"
                  @click="closeEmployee"
                >
                  {{ str['cancel'] }}
                </v-btn>
                <v-btn
                  color="success"
                  @click="saveEmployee"
                >
                  {{ str['save'] }}
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-dialog
            v-if="historicData"
            v-model="dialogHistoric"
            persistent
            max-width="1000px"
          >
            <v-card>
              <v-card-title>
                <span
                  v-if="!historicData.user"
                  class="headline"
                >
                  {{ str['historic'] }}
                </span>
                <span
                  v-if="historicData.user"
                  class="headline"
                >
                  {{ str['work_register'] }} - {{ historicData.user.name }}
                </span>
              </v-card-title>
              <v-card-text>
                <v-row>
                  <v-col
                    v-if="historicTabs.length > 1"
                    :cols="12"
                  >
                    <v-tabs
                      v-model="historicTab"
                      background-color="transparent"
                      color="secondary"
                      style="margin-top: 0;"
                      grow
                      show-arrows
                      @change="applyChatHistoricDate"
                    >
                      <v-tab
                        v-for="item in historicTabs"
                        :key="item.id"
                      >
                        {{ item.title }}
                      </v-tab>
                    </v-tabs>
                  </v-col>
                  <v-col
                    v-if="!historicData.dashboardLoading"
                    :cols="12"
                    class="pt-0 pb-2"
                  >
                    <v-col
                      :cols="12"
                      class="pt-0 pb-0 pl-0 pr-0"
                    >
                      <div style="display: flex; justify-content: space-between;">
                        <div
                          style="width: 100%;"
                          class="pr-2"
                        >
                          <v-text-field
                            v-if="historicTabs[historicTab].search"
                            v-model="historicDataSearch"
                            append-icon="mdi-magnify"
                            :label="str['search']"
                            single-line
                            hide-details
                            style="max-width: 300px;"
                          />
                          <v-autocomplete
                            v-if="historicTabs[historicTab].select.enabled"
                            v-model="historicTabs[historicTab].select.value"
                            :label="str['filter']"
                            item-text="label"
                            item-value="value"
                            :items="historicTabs[historicTab].select.items"
                            hide-details
                            :style="{'max-width': historicTabs[historicTab].select.large ? '320px' : '200px'}"
                            @change="applyChatHistoricDate()"
                            :no-data-text="str['no_data']"
                          />
                        </div>
                        <div style="min-width: 200px; width: 100%; max-width: 200px;">
                          <v-menu
                            v-model="datepickerHistoricMenu"
                            :close-on-content-click="false"
                            :nudge-right="40"
                            transition="scale-transition"
                            offset-y
                            min-width="290px"
                            style="display: block;"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-text-field
                                v-if="historicTabs[historicTab].datepicker !== 'single'"
                                v-model="datesHistoricText"
                                :label="str['interval_dates']"
                                readonly
                                v-bind="attrs"
                                v-on="on"
                                hide-details
                              />
                              <v-text-field
                                v-if="historicTabs[historicTab].datepicker === 'single'"
                                v-model="singleDateHistoricText"
                                :label="str['date']"
                                readonly
                                v-bind="attrs"
                                v-on="on"
                                hide-details
                              />
                            </template>
                            <v-date-picker
                              v-if="historicTabs[historicTab].datepicker !== 'single'"
                              v-model="historicData.dates"
                              range
                              style="margin: 0;"
                              @change="applyDashboardHistoricDates"
                              :locale="datepickerLanguage"
                            />
                            <v-date-picker
                              v-if="historicTabs[historicTab].datepicker === 'single'"
                              v-model="historicData.date"
                              style="margin: 0;"
                              @change="applyChatHistoricDate"
                              :locale="datepickerLanguage"
                            />
                          </v-menu>
                        </div>
                      </div>
                      <div
                        v-if="historicTabs[historicTab].id === 'chat' && historicTabs[historicTab].select.value !== 'list' && !historicData.chatLoading"
                        class="pt-4 row-flex-align"
                      >
                        <div class="row-flex-align">
                          <h5 class="mr-2">
                            {{ str['hours'] }}: 
                          </h5>
                          <v-text-field
                            v-model="historicTabs[historicTab].averageHours"
                            style="padding: 0; margin: 0;"
                            class="input-only-number"
                            hide-details
                          />
                        </div>
                        <div class="row-flex-align pl-5">
                          <h5>
                            {{ str['characters'] }}: {{ (historicChatAverageHours.characters ? historicChatAverageHours.characters : '0') }} {{ str['per_half_hour'] }}
                          </h5>
                          <span class="ml-3 mr-3">|</span>
                          <h5>
                            {{ str['messages'] }}: {{ (historicChatAverageHours.messages ? historicChatAverageHours.messages : '0') }} {{ str['per_half_hour'] }}
                          </h5>
                        </div>
                      </div>
                    </v-col>
                  </v-col>
                  <v-col
                    :cols="12"
                  >
                    <v-tabs-items
                      v-model="historicTab"
                      style="min-height: calc(100% - 115px);"
                    >
                      <v-tab-item
                        v-for="item in historicTabs"
                        :key="item.id"
                      >
                        <div
                          v-if="historicTabs[historicTab].id === 'historic'"
                          class="custom-scroll"
                          style="min-height: 56vh; max-height: 56vh;"
                        >
                          <div v-if="historicData.dashboardLoading">
                            <v-col
                              class="text-center"
                            >
                              <v-progress-circular
                                indeterminate
                                color="primary"
                              ></v-progress-circular>
                            </v-col>
                          </div>
                          <div v-if="!historicData.dashboardLoading">
                            <v-data-table
                              :headers="historicData.user ? historicHeaders : globalHistoricHeaders"
                              :items="historicDashboardFiltered"
                              sort-by="date"
                              :sort-desc="true"
                              :no-data-text="str['no_history_date_range']"
                              :no-results-text="str['no_history_date_range']"
                              class="elevation-1"
                              style="width: 100%;"
                              @click:row="openClient"
                            >
                              <template v-slot:item.description="{ item }">
                                {{ item.description }}
                                <v-icon
                                  v-if="item.notes"
                                  color="info"
                                  dark
                                  class="cursor-hover ml-1"
                                  @click.stop="showInformation(item.notes)"
                                >
                                  mdi-information
                                </v-icon>
                              </template>
                              <template v-slot:item.client="{ item }">
                                {{ item.action ? item.action : str['n_a'] }}
                              </template>
                              <template v-slot:item.chat="{ item }">
                                <v-icon
                                  v-if="item.action && !item.hide_chat"
                                  color="blue"
                                  class="cursor-hover"
                                  @click.stop="openClientChat(item.action)"
                                >
                                  mdi-chat
                                </v-icon>
                                <div v-if="item.action && item.hide_chat">
                                  -
                                </div>
                              </template>
                              <template v-slot:footer.page-text="{ pageStart, pageStop, itemsLength }">
                                {{ pageStart }}-{{ pageStop }} {{ str['of'] }} {{ itemsLength }}
                              </template>
                            </v-data-table>
                          </div>
                        </div>
                        <div
                          v-if="historicTabs[historicTab].id === 'old_historic'"
                          class="custom-scroll"
                          style="min-height: 56vh; max-height: 56vh;"
                        >
                          <div v-if="historicData.dashboardLoading">
                            <v-col
                              class="text-center"
                            >
                              <v-progress-circular
                                indeterminate
                                color="primary"
                              ></v-progress-circular>
                            </v-col>
                          </div>
                          <div
                            v-if="!historicData.dashboardLoading"
                          >
                            <v-col
                              v-if="!oldHistoricDashboardFiltered.length"
                              class="text-center"
                            >
                              {{ str['no_history_date_range'] }}
                            </v-col>
                            <v-col
                              v-for="historicItem in oldHistoricDashboardFiltered"
                              :key="historicItem.id"
                              cols="12"
                              style="padding-top: 0; padding-bottom: 0;"
                            >
                              <b>{{ historicItem.date }}:</b> {{ historicItem.description }}
                            </v-col>
                          </div>
                        </div>
                        <div
                          v-if="historicTabs[historicTab].id === 'chat'"
                          class="custom-scroll"
                          style="min-height: 56vh; max-height: 56vh;"
                        >
                          <div v-if="historicData.chatLoading">
                            <v-col
                              class="text-center"
                            >
                              <v-progress-circular
                                indeterminate
                                color="primary"
                              ></v-progress-circular>
                            </v-col>
                          </div>
                          <div
                            v-if="historicTabs[historicTab].select.value === 'list' && !historicData.chatLoading"
                            class="custom-scroll"
                            style="min-height: 320px; max-height: 56vh;"
                          >
                            <v-data-table
                              :headers="historicData.user ? historicHeaders : globalHistoricHeaders"
                              :items="historicData.chatLogs"
                              sort-by="date"
                              :sort-desc="true"
                              :no-data-text="str['no_history_date_range']"
                              :no-results-text="str['no_history_date_range']"
                              class="elevation-1"
                              style="width: 100%;"
                              @click:row="openClientChat"
                            >
                              <template v-slot:item.description="{ item }">
                                {{ item.description }}
                                <v-icon
                                  v-if="item.message_id"
                                  color="info"
                                  dark
                                  class="cursor-hover ml-1"
                                  @click.stop="showMessage(item)"
                                >
                                  mdi-information
                                </v-icon>
                              </template>
                              <template v-slot:item.client="{ item }">
                                {{ item.action ? item.action : str['n_a'] }}
                              </template>
                              <template v-slot:item.chat="{ item }">
                                <v-icon
                                  v-if="item.action && !item.hide_chat"
                                  color="blue"
                                  class="cursor-hover"
                                  @click.stop="openClientChat(item.action)"
                                >
                                  mdi-chat
                                </v-icon>
                                <div v-if="item.action && item.hide_chat">
                                  -
                                </div>
                              </template>
                              <template v-slot:footer.page-text="{ pageStart, pageStop, itemsLength }">
                                {{ pageStart }}-{{ pageStop }} {{ str['of'] }} {{ itemsLength }}
                              </template>
                            </v-data-table>
                          </div>
                          <div
                            v-if="historicTabs[historicTab].select.value !== 'list' && !historicData.chatLoading"
                            class="custom-scroll chart-line-custom"
                            style="min-height: 320px; max-height: 56vh;"
                            :class="historicData.chat.class"
                          >
                            <base-material-chart-card
                              :data="historicData.chat"
                              :options="historicData.chat.options"
                              :type="historicData.chat.type"
                              :color="historicData.chat.color"
                              :size="historicData.chat.size"
                              :sizelimit="historicData.chat.sizelimit"
                              :shadow="true"
                              hover-reveal
                            >
                              <div class="custom-chart-legend">
                                <div
                                  v-for="(itemVal, indexVal) in historicData.chat.legend"
                                  :key="indexVal"
                                  @click="filterHistoricChatLegend(itemVal)"
                                  :style="{'opacity': itemVal.active ? '1' : '0.5'}"
                                >
                                  <span :style="{backgroundColor: itemVal.color}"></span>
                                  {{ itemVal.label }}
                                  <v-icon
                                    size="16"
                                    class="ml-1"
                                  >
                                    mdi-eye
                                  </v-icon>
                                </div>
                              </div>
                            </base-material-chart-card>
                          </div>
                        </div>
                      </v-tab-item>
                    </v-tabs-items>
                  </v-col>
                </v-row>
              </v-card-text>
              <v-card-actions>
                <v-spacer />
                <v-btn
                  color="default"
                  @click="closeHistoric"
                >
                  {{ str['close'] }}
                </v-btn>
                <v-spacer />
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-dialog
            v-if="searchClientLogsData"
            v-model="dialogSearchClientLogs"
            persistent
            max-width="1000px"
          >
            <v-card>
              <v-card-title>
                <div>
                  <span class="headline">
                    {{ str['search_by_client'] }}
                  </span>
                </div>
              </v-card-title>
              <v-card-text>
                <v-row>
                  <v-col
                    :cols="12"
                    class="custom-scroll"
                  >
                    <div style="display: flex; justify-content: space-between; align-items: flex-end;">
                      <div class="row-flex-align">
                        <v-text-field
                          v-model="searchClientLogsData.clientSearchValue"
                          :label="str['client']"
                          single-line
                          hide-details
                          style="max-width: 100px; padding: 0; margin: 0;"
                        />
                        <v-btn
                          color="blue"
                          dark
                          @click="searchClientById()"
                          class="ml-6"
                          v-bind:class="{'custom-disabled': !searchClientLogsData.clientSearchValue}"
                        >
                          {{ str['search'] }}
                        </v-btn>
                      </div>
                      <div style="min-width: 200px; width: 100%; max-width: 200px;">
                        <v-menu
                          v-model="datepickerSearchClientLogsMenu"
                          :close-on-content-click="false"
                          :nudge-right="40"
                          transition="scale-transition"
                          offset-y
                          min-width="290px"
                          style="display: block;"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                              v-model="searchClientLogsDatesText"
                              :label="str['interval_dates']"
                              readonly
                              v-bind="attrs"
                              v-on="on"
                              hide-details
                            />
                          </template>
                          <v-date-picker
                            v-model="searchClientLogsData.dates"
                            range
                            style="margin: 0;"
                            @change="applySearchClientLogs"
                            :locale="datepickerLanguage"
                          />
                        </v-menu>
                      </div>
                    </div>
                  </v-col>
                  <v-col
                    v-if="searchClientLogsData.loading"
                    :cols="12"
                    class="custom-scroll"
                    style="min-height: 56vh; max-height: 56vh;"
                  >
                    <v-col
                      class="text-center"
                    >
                      <v-progress-circular
                        indeterminate
                        color="primary"
                      ></v-progress-circular>
                    </v-col>
                  </v-col>
                  <v-col
                    v-if="!searchClientLogsData.loading && searchClientLogsData.client"
                    :cols="12"
                  >
                    <div
                      class="pr-2"
                      style="display: flex; justify-content: space-between; align-items: center;"
                    >
                      <div
                        class="success--text cursor-hover"
                        @click="openClient(searchClientLogsData.client.id)"
                      >
                        {{ searchClientLogsData.client.name }}
                      </div>
                      <div style="max-width: 100%; width: 300px;">
                        <v-text-field
                          v-model="searchClientLogsData.logsSearchValue"
                          append-icon="mdi-magnify"
                          :label="str['search']"
                          single-line
                          hide-details
                          style="max-width: 300px; margin: 0; padding: 0;"
                        />
                      </div>
                    </div>
                  </v-col>
                  <v-col
                    v-if="!searchClientLogsData.loading"
                    :cols="12"
                    class="custom-scroll pt-0"
                    style="min-height: 56vh; max-height: 56vh;"
                  >
                    <div
                      v-if="!searchClientLogsData.client"
                      style="text-align: center; padding: 40px 20px;"
                    >
                      {{ str['to_start_search_by_client'] }}
                    </div>
                    <div
                      v-if="searchClientLogsData.client"
                    >
                      <div v-if="!searchClientLogsData.loading">
                        <v-data-table
                          :headers="searchClientLogsHeaders"
                          :items="searchClientLogsData.logs"
                          :search="searchClientLogsData.logsSearchValue"
                          sort-by="date"
                          :sort-desc="true"
                          :no-data-text="str['no_history_date_range']"
                          :no-results-text="str['no_history_date_range']"
                          class="elevation-1"
                          style="width: 100%;"
                          @click:row="openClientChat(searchClientLogsData.client.id)"
                        >
                          <template v-slot:item.description="{ item }">
                            {{ item.description }}
                            <v-icon
                              v-if="item.message_id"
                              color="info"
                              dark
                              class="cursor-hover ml-1"
                              @click.stop="showMessage(item)"
                            >
                              mdi-information
                            </v-icon>
                            <v-icon
                              v-if="item.notes"
                              color="info"
                              dark
                              class="cursor-hover ml-1"
                              @click.stop="showInformation(item.notes)"
                            >
                              mdi-information
                            </v-icon>
                          </template>
                          <template v-slot:footer.page-text="{ pageStart, pageStop, itemsLength }">
                            {{ pageStart }}-{{ pageStop }} {{ str['of'] }} {{ itemsLength }}
                          </template>
                        </v-data-table>
                      </div>
                    </div>
                  </v-col>
                </v-row>
              </v-card-text>
              <v-card-actions>
                <v-spacer />
                <v-btn
                  color="default"
                  @click="closeSearchClientLogsDialog"
                >
                  {{ str['close'] }}
                </v-btn>
                <v-spacer />
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-dialog
            v-if="assignClientsData"
            v-model="dialogAssignClients"
            persistent
            max-width="750px"
          >
            <v-card>
              <v-card-title>
                <span class="headline">
                  {{ assignClientsData.name }}
                </span>
              </v-card-title>
              <v-card-text>
                <v-row>
                  <v-data-table
                    :headers="assignClientsheaders"
                    :items="assignClientsData.logs"
                    sort-by="date"
                    :sort-desc="true"
                    :no-data-text="str['clients_empty']"
                    :no-results-text="str['clients_empty']"
                    class="elevation-1"
                    style="width: 100%;"
                  >
                    <template v-slot:footer.page-text="{ pageStart, pageStop, itemsLength }">
                      {{ pageStart }}-{{ pageStop }} {{ str['of'] }} {{ itemsLength }}
                    </template>
                  </v-data-table>
                </v-row>
              </v-card-text>
              <v-card-actions>
                <v-spacer />
                <v-btn
                  color="default"
                  @click="closeAssignClients"
                >
                  {{ str['close'] }}
                </v-btn>
                <v-spacer />
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-toolbar>
      </template>
      <template v-slot:item.photo="{ item }">
        <div
          class="p-2 text-center"
          style="display: flex; justify-content: center; padding: 10px 0;"
        >
          <v-img
            contain
            :src="item.photo"
            :alt="item.name"
            width="60px"
            height="60px"
          />
        </div>
      </template>
      <template v-slot:item.id="{ item }">
        <div>
          <span>
            {{ item.id }}
          </span>
          <i
            class="mdi mdi-content-copy ml-1 cursor-hover"
            @click.stop="copyTextToClipboard(item.id)"
          />
        </div>
      </template>
      <template v-slot:item.status="{ item }">
        <span
          v-if="clientsStatusDict[item.status]"
          v-bind:class="[clientsStatusDict[item.status] && clientsStatusDict[item.status].type ? (clientsStatusDict[item.status].type + '--text') : '']"
        >
          {{ clientsStatusDict[item.status].label }}
        </span>
        <span
          v-if="!clientsStatusDict[item.status]"
        >
          {{ str['n_a'] }}
        </span>
      </template>
      <template v-slot:item.sub_type="{ item }">
        {{ employeeSubTypeDict[item.sub_type] ? employeeSubTypeDict[item.sub_type].label : str['n_a'] }}
      </template>
      <template v-slot:item.actions="{ item }">
        <v-icon
          v-if="showClients && editPermission && !isEmployee"
          small
          class="mr-2"
          color="success lighten-2"
          @click.stop="openEmployeeClients(item)"
        >
          mdi-account-group
        </v-icon>
        <v-icon
          v-if="showClientsPerMonth && editPermission && !isEmployee"
          small
          class="mr-2"
          color="teal lighten-2"
          @click.stop="showAssignClients(item)"
        >
          mdi-clipboard-text-clock
        </v-icon>
        <v-icon
          v-if="historicAvailable"
          small
          class="mr-2"
          color="teal lighten-2"
          @click.stop="showHistoric(item)"
        >
          mdi-clipboard-text-clock
        </v-icon>
        <v-icon
          v-if="editPermission && !isEmployee && item.status !== statusMap.active"
          small
          class="mr-2"
          color="success"
          @click.stop="activeEmployee(item)"
        >
          mdi-account-check
        </v-icon>
        <v-icon
          v-if="editPermission && !isEmployee && item.status !== statusMap.suspended"
          small
          class="mr-2"
          color="warning"
          @click.stop="suspendEmployee(item)"
        >
          mdi-lock
        </v-icon>
        <v-icon
          v-if="editPermission && !isEmployee && item.status !== statusMap.blocked"
          small
          class="mr-0"
          color="error"
          @click.stop="blockEmployee(item)"
        >
          mdi-block-helper
        </v-icon>
      </template>
      <template v-slot:footer.page-text="{ pageStart, pageStop, itemsLength }">
        {{ pageStart }}-{{ pageStop }} {{ str['of'] }} {{ itemsLength }}
      </template>
    </v-data-table>

    <v-dialog
      v-model="dialogInformation"
      scrollable
      persistent
      max-width="650px"
    >
      <v-card>
        <v-card-title>
          <span class="headline">
            {{ str['notes'] }}
          </span>
        </v-card-title>
        <v-card-text>
          <div style="white-space: pre;">
            {{ dialogInformationValue }}
          </div>
        </v-card-text>
        <v-card-actions style="display: flex; justify-content: center;">
          <v-btn
            color="default"
            @click="closeInformation()"
          >
            {{ str['close'] }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="dialogMessage"
      scrollable
      persistent
      max-width="650px"
    >
      <v-card>
        <v-card-text
          v-if="dialogMessageData"
          class="pt-6"
        >
          <div
            v-if="dialogMessageData.audio"
            class="row-align-center"
            :class="{'mb-4': dialogMessageData.text}"
          >
            <audio controls>
              <source
                :src="dialogMessageData.audio"
                type="audio/mp3"
              >
            </audio>
          </div>
          <div
            v-if="dialogMessageData.image"
            class="row-align-center"
            :class="{'mb-4': dialogMessageData.text}"
          >
            <img
              :src="dialogMessageData.image"
              style="max-width: 350px; max-height: 350px;"
            />
          </div>
          <div
            v-if="dialogMessageData.video"
            class="row-align-center"
            :class="{'mb-4': dialogMessageData.text}"
          >
            <video
              :src="dialogMessageData.video"
              style="max-width: 80%; max-height: 500px;"
              controls
            ></video>
          </div>
          <div
            v-if="dialogMessageData.text"
            v-html="dialogMessageData.text"
            class="row-align-center text-center"
            style="font-size: 18px;"
          ></div>
        </v-card-text>
        <v-card-actions style="display: flex; justify-content: center;">
          <v-btn
            color="default"
            @click="closeMessage()"
          >
            {{ str['close'] }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-if="dialogClientsEmployee"
      v-model="dialogClients"
      persistent
      max-width="900px"
    >
      <v-card>
        <v-card-title>
          <span class="headline">
            {{ dialogClientsEmployee.name }} - {{ str['clients'] }}
          </span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col
                cols="12"
              >
                <v-data-table
                  :headers="clientsTableHeaders"
                  :items="clientsTableItems"
                  :search="clientsTableSearch"
                  :no-data-text="str['clients_empty']"
                  :no-results-text="str['clients_empty']"
                  @click:row="openClient"
                >
                  <template v-slot:top>
                    <v-toolbar
                      flat
                    >
                      <div style="width: 100%; display: flex; justify-content: space-between;">
                        <div
                          class="mr-4"
                          style="width: 100%; max-width: 500px;"
                        >
                          <v-text-field
                            v-model="clientsTableSearch"
                            append-icon="mdi-magnify"
                            :label="str['search']"
                            single-line
                            hide-details
                          />
                        </div>
                        <div class="datepicker-element">
                          <v-menu
                            v-model="clientsTableMenu"
                            :close-on-content-click="false"
                            :nudge-right="40"
                            transition="scale-transition"
                            offset-y
                            min-width="290px"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-text-field
                                v-model="clientsTableDatesText"
                                :label="str['interval_dates']"
                                readonly
                                v-bind="attrs"
                                v-on="on"
                                hide-details
                              />
                            </template>
                            <v-date-picker
                              v-model="clientsTableDates"
                              range
                              style="margin: 0;"
                              @change="openEmployeeClients()"
                              :locale="datepickerLanguage"
                            />
                          </v-menu>
                        </div>
                      </div>
                      <v-spacer />
                    </v-toolbar>
                  </template>
                  <template v-slot:item.date="{ item }">
                    {{ convertUtcDate(item.date) }}
                  </template>
                  <template v-slot:footer.page-text="{ pageStart, pageStop, itemsLength }">
                    {{ pageStart }}-{{ pageStop }} {{ str['of'] }} {{ itemsLength }}
                  </template>
                </v-data-table>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="default"
            @click="closeEmployeeClients"
          >
            {{ str['close'] }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="dialogSelectEmployeePermissions"
      scrollable
      persistent
      max-width="400px"
    >
      <v-card>
        <v-card-title>
          <span class="headline">
            {{ str['use_permissions'] }}
          </span>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="modal-scroll">
          <v-col
            cols="12"
            class="pt-0 modal-filters-panel"
          >
            <v-row>
              <v-col
                :cols="12"
                class="pt-0"
              >
                <v-text-field
                  v-model="selectEmployeePermissionsSearchValue"
                  append-icon="mdi-magnify"
                  :label="str['search']"
                  single-line
                  hide-details
                />
              </v-col>
            </v-row>
          </v-col>
          <div
            v-for="emp in employeesPermissionsFiltered"
            :key="emp.id"
            @click="selectEmployeePermissions(emp)"
            class="box-select-item"
          >
            <span>
              {{ emp.name }}
            </span>
          </div>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="row-flex-align-center">
          <v-btn
            color="default"
            @click="dialogSelectEmployeePermissions = false"
          >
            {{ str['cancel'] }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
  import Api from '@/services/Api'
  import ChatApi from '@/services/ChatApi'
  import StorageApi from '@/services/StorageApi'
  import Utils from '@/services/Utils'
  import moment from 'moment'
  import axios from 'axios'

  export default {
    data () {
      const user = Utils.getUser()
      const config = user && user.configurations ? user.configurations : null
      const hasSubType = config && config.employees && config.employees.has_sub_type ? true : false
      const hasProfessionalLicense = config && config.employees && config.employees.has_professional_license ? true : false
      const showPhotos = false
      const defaultHeaders = [
        { text: window.strings['photography'], align: 'center', value: 'photo', width: 120 },
        { text: window.strings['id'], value: 'id', width: 120 },
        { text: window.strings['name'], value: 'name', align: 'center', width: 180 },
        { text: window.strings['status'], value: 'status', align: 'center', width: 100 },
        { text: window.strings['email'], value: 'email', align: 'center', width: 140 },
        { text: window.strings['contact'], value: 'mobile_phone', align: 'center', width: 150 },
        { text: window.strings['sub_type'], value: 'sub_type', align: 'center', width: 120 },
        { text: window.strings['actions'], value: 'actions', sortable: false, align: 'center', width: 120 },
      ]
      const headers = []
      for (let h = 0; h < defaultHeaders.length; h++) {
        let pushHeader = true
        if (defaultHeaders[h].value === 'photo' && !showPhotos) {
          pushHeader = false
        }
        if (defaultHeaders[h].value === 'sub_type' && !hasSubType) {
          pushHeader = false
        }
        if (pushHeader) {
          headers.push(defaultHeaders[h])
        }
      }

      let historicAvailable = false
      if (config && config.employees && config.employees.work_log) {
        historicAvailable = true
      }

      let showClientsPerMonth = false
      if (config && config.employees && config.employees.show_clients_per_month) {
        showClientsPerMonth = true
      }

      let showClients = false
      if (config && config.employees && config.employees.show_clients) {
        showClients = true
      }

      let showChatHistoric = false
      if (config && config.employees && config.employees.chat_log) {
        showChatHistoric = true
      }

      let showEmployeeTabs = false
      if (config && config.employees && config.employees.tabs) {
        showEmployeeTabs = true
      }

      let showEmployeeSchedule = false
      if (config && config.employees && config.employees.schedule) {
        showEmployeeSchedule = true
      }

      let isEmployee = false
      if (user && Utils.isEmployee(user.type)) {
        isEmployee = true
      }

      const employeeTabs = [{
        label: 'details',
        value: 'details'
      }]
      if (!isEmployee) {
        employeeTabs.push({
          label: 'permissions',
          value: 'permissions'
        })
      }
      if (showEmployeeSchedule) {
        employeeTabs.push({
          label: 'schedule',
          value: 'schedule'
        })
      }

      let showChat = false
      if (config && config.chat && Utils.hasPermission('chat_view')) {
        showChat = true
      }

      const globalHistoricHeaders = [
        { text: window.strings['date'], value: 'date', align: 'center' },
        { text: window.strings['action'], value: 'description', align: 'center' },
        { text: window.strings['employee'], value: 'employee_name', align: 'center' },
        { text: window.strings['client_id'], value: 'client', sortable: false, align: 'center' },
      ]
      if (showChat) {
        globalHistoricHeaders.push({ text: window.strings['chat'], value: 'chat', sortable: false, align: 'center' })
      }

      return {
        str: window.strings,
        datepickerLanguage: window.datepickerLanguage,
        user: user,
        convertUtcDate: Utils.convertUtcDate,
        hasSubType: hasSubType,
        hasProfessionalLicense: hasProfessionalLicense,
        historicAvailable: historicAvailable,
        showEmployeeTabs: showEmployeeTabs,
        showEmployeeSchedule: showEmployeeSchedule,
        showClientsPerMonth: showClientsPerMonth,
        showClients: showClients,
        showChat: showChat,
        employees: [],
        showPhotos: showPhotos,
        editedIndex: -1,
        dialogEmployee: false,
        datepickerMenu: false,
        employeeData: [],
        permissions: [],
        search: '',
        headers: headers,
        dialogHistoric: false,
        historicData: null,
        datepickerHistoricMenu: false,
        clientsStatusDict: this.getClientsStatusDict(),
        statusMap: {
          active: 0,
          suspended: 1,
          blocked: 2,
        },
        employeeTabs: employeeTabs,
        employeeTab: 0,
        dialogAssignClients: false,
        assignClientsData: null,
        assignClientsheaders: [
          { text: window.strings['date'], value: 'date', align: 'center' },
          { text: window.strings['clients'], value: 'description', align: 'center' },
        ],
        showChatHistoric: showChatHistoric,
        historicTabs: [],
        historicTab: 0,
        historicDataSearch: '',
        oldHistoricCategory: 0,
        historicCategoryDict: this.getHistoricCategoryDict(),
        chatCategoryDict: this.getChatCategoriesDict(),
        historicHeaders: [
          { text: window.strings['date'], value: 'date', align: 'center' },
          { text: window.strings['action'], value: 'description', align: 'center' },
          { text: window.strings['client_id'], value: 'client', sortable: false, align: 'center' },
        ],
        globalHistoricHeaders: globalHistoricHeaders,
        editPermission: Utils.hasPermission('employees_edit') ? true : false,
        isEmployee: isEmployee,
        scheduleHeaders: [
          { text: window.strings['day'], value: 'weekday', align: 'left' },
          { text: window.strings['start_time'], value: 'start_time', align: 'center' },
          { text: window.strings['end_time'], value: 'end_time', align: 'center' },
          { text: window.strings['status'], value: 'status', align: 'center' },
        ],
        scheduleData: [],
        scheduleStatusItems: Utils.getEmployeeScheduleStatus(),
        scheduleStatusDict: this.getScheduleStatusDict(),
        scheduleDaysDict: this.getScheduleDaysDict(),
        hours: Array.from({length: 24}, function (_, index) {
          const formattedIndex = index.toString().padStart(2, '0');
          return { label: formattedIndex, value: formattedIndex };
        }),
        minutes: Array.from({length: 60}, function (_, index) {
          const formattedIndex = index.toString().padStart(2, '0');
          return { label: formattedIndex, value: formattedIndex };
        }),
        searchClientLogsData: null,
        dialogSearchClientLogs: false,
        datepickerSearchClientLogsMenu: false,
        searchClientLogsHeaders: [
          { text: window.strings['date'], value: 'date', align: 'center' },
          { text: window.strings['action'], value: 'description', align: 'center' },
          { text: window.strings['employee'], value: 'employee_name', sortable: false, align: 'center' },
        ],
        dialogInformation: false,
        dialogInformationValue: null,
        employeesDict: {},
        messageMediaTypes: {
          text: 0,
          image: 1,
          audio: 2,
          video: 3,
        },
        dialogMessage: false,
        dialogMessageData: null,
        dialogClients: false,
        dialogClientsEmployee: null,
        clientsTableHeaders: [
          { text: window.strings['id'], value: 'id', align: 'left', width: 60 },
          { text: window.strings['name'], value: 'name', align: 'left', width: 130 },
          { text: window.strings['email'], value: 'email', align: 'left', width: 180 },
          { text: window.strings['mobile_phone'], value: 'mobile_phone', align: 'center', width: 160 },
          { text: window.strings['registration_validated_date'], value: 'register_date', align: 'center', width: 170 },
        ],
        clientsTableItems: [],
        clientsTableSearch: '',
        clientsTableMenu: false,
        clientsTableDates: [null, null],
        employeeSubTypeDict: this.getEmployeeSubTypeDict(),
        dialogSelectEmployeePermissions: false,
        selectEmployeePermissionsSearchValue: '',
      }
    },
    watch: {
      dialogClients (val) {
        const dialog = document.getElementsByClassName('v-dialog')
        if (dialog && dialog[0]) {
          dialog[0].scrollTo(0, 0)
        }
        val || this.closeDialog()
      },
    },
    computed: {
      formTitle () {
        return this.editedIndex === -1 ? window.strings['new_employee'] : window.strings['edit_employee']
      },
      datesHistoricText () {
        return this.historicData.dates.join(' / ')
      },
      singleDateHistoricText () {
        return this.historicData.date
      },
      historicDashboardFiltered () {
        const self = this
        const filterValue = this.getHistoryTabSelectFilter('historic')
        return this.historicData.dashboard.filter(function (item) {
          return item.category > self.oldHistoricCategory && (!filterValue || filterValue === item.category)
        })
      },
      oldHistoricDashboardFiltered () {
        const self = this
        const search = this.historicDataSearch.toLowerCase()
        return this.historicData.dashboard.filter(function (item) {
          return item.category === self.oldHistoricCategory && (!search || (item.description && item.description.toLowerCase().indexOf(search) > -1))
        })
      },
      searchClientLogsDatesText () {
        return this.searchClientLogsData.dates.join(' / ')
      },
      historicChatAverageHours () {
        try {
          const charactersTotal = this.historicData.chatData.reduce((total, item) => total + item.characters_total, 0)
          const messagesTotal = this.historicData.chatData.reduce((total, item) => total + item.messages_total, 0)
          const hours = parseFloat(this.historicTabs[this.historicTab].averageHours) * 2
          const charactersAverage = Math.floor(charactersTotal / hours)
          const messageAverage = Math.floor(messagesTotal / hours)
          return {
            characters: charactersAverage,
            messages: messageAverage
          }
        } catch { }
        return null
      },
      clientsTableDatesText () {
        if (this.clientsTableDates[0] && this.clientsTableDates[1]) {
          return this.clientsTableDates.join(' / ')
        } else {
          return ''
        }
      },
      employeesPermissionsFiltered: function () {
        let employees = this.employees
        if (this.selectEmployeePermissionsSearchValue) {
          const searchValue = this.selectEmployeePermissionsSearchValue.toLowerCase()
          employees = employees.filter(function (item) {
            return item.name.toLowerCase().indexOf(searchValue) > -1
          })
        }
        return employees
      },
    },
    watch: {
      dialogEmployee (val) {
        const dialog = document.getElementsByClassName('v-dialog')
        if (dialog && dialog[0]) {
          dialog[0].scrollTo(0, 0)
        }

        val || this.closeEmployee()
      },
    },
    beforeMount: function () {
      if (!this.user) {
        return false
      }
      this.setPermissions()
      this.setEmployeeDataFields()
      this.getEmployees()
      if (this.historicAvailable) {
        this.setHistoricTabs()
      }
    },
    methods: {
      setPermissions: function () {
        const config = this.user && this.user.configurations ? this.user.configurations : null
        const permissions = config && config.employees.permissions
        const data = []
        if (permissions) {
          for (let i = 0; i < permissions.length; i++) {
            data.push({
              id: permissions[i].id,
              title: permissions[i].title,
              parentTitle: !this.showEmployeeTabs && i === 0 ? window.strings['permissions'] : null,
              parentSubtitle: permissions[i].parent ? permissions[i].parent : null,
              info: permissions[i].info ? permissions[i].info : null,
              type: 'checkbox',
              isPermission: true,
              visible: true,
              value: false,
            })
          }
        }
        this.permissions = data
      },
      setEmployeeDataFields: function () {
        const employeeData = [{
          type: 'input',
          id: 'name',
          title: window.strings['name'],
          value: '',
          visible: true,
        }, {
          type: 'input',
          id: 'email',
          title: window.strings['email'],
          value: '',
          visible: true,
        }, {
          type: 'input',
          id: 'password',
          title: window.strings['password'],
          value: '',
          visible: true,
          disabled: true,
        }, {
          type: 'datepicker',
          id: 'birth_date',
          title: window.strings['birth_date'],
          value: '',
          visible: true,
        }, {
          type: 'select',
          id: 'gender',
          title: window.strings['gender'],
          items: Utils.getGenders(),
          value: '',
          visible: true,
        }, {
          type: 'input',
          id: 'mobile_phone',
          title: window.strings['contact'],
          value: '',
          visible: true,
        }]
        if (this.hasSubType) {
          employeeData.push({
            type: 'select',
            id: 'sub_type',
            title: window.strings['sub_type'],
            items: Utils.getEmployeeSubTypes(),
            value: '',
            visible: true,
          })
        }
        if (this.hasProfessionalLicense) {
          employeeData.push({
            type: 'input',
            id: 'professional_license',
            title: window.strings['professional_license'],
            value: '',
            visible: true,
          })
        }
        this.employeeData = employeeData.concat(this.permissions)
      },
      setHistoricTabs: function () {
        const historicCategories = JSON.parse(JSON.stringify(Utils.getEmployeeHistoricCategories()))
        historicCategories.unshift({
          value: null,
          label: window.strings['all']
        })
        const historicTabs = [{
          id: 'historic',
          title: window.strings['historic'],
          datepicker: 'multiple',
          search: false,
          select: {
            enabled: true,
            large: true,
            items: historicCategories,
            value: null,
            defaultValue: null
          }
        }]
        if (this.showChatHistoric) {
          historicTabs.push({
            id: 'chat',
            title: window.strings['chat'],
            datepicker: 'single',
            search: false,
            averageHours: '8',
            defaultAverageHours: '8',
            select: {
              enabled: true,
              large: false,
              items: [{
                value: 'list',
                label: window.strings['list']
              }, {
                value: 'chart',
                label: window.strings['chart'],
                keys: [{
                  value: 'characters_total',
                  label: window.strings['total_characters'],
                  unit: 'caracteres',
                  color: '#edad49'
                }, {
                  value: 'messages_total',
                  label: window.strings['total_messages'],
                  unit: 'mensagens',
                  color: '#3476bd'
                }]
              }],
              value: 'list',
              defaultValue: 'list'
            }
          })
        }
        this.historicTabs = historicTabs
      },
      getClientsStatusDict: function () {
        const list = Utils.getUserStatus()
        const dict = {}
        if (list && list.length) {
          list.forEach(function (item) {
            dict[item.value] = item
          })
        }
        return dict
      },
      getHistoricCategoryDict: function () {
        const list = Utils.getEmployeeHistoricCategories()
        const dict = {}
        if (list && list.length) {
          list.forEach(function (item) {
            dict[item.value] = item
          })
        }
        return dict
      },
      getChatCategoriesDict: function () {
        const list = Utils.getChatHistoricCategories()
        const dict = {}
        if (list && list.length) {
          list.forEach(function (item) {
            dict[item.value] = item
          })
        }
        return dict
      },
      getScheduleStatusDict: function () {
        const list = Utils.getEmployeeScheduleStatus()
        const dict = {}
        if (list && list.length) {
          list.forEach(function (item) {
            dict[item.value] = item
          })
        }
        return dict
      },
      getScheduleDaysDict: function () {
        const list = Utils.getEmployeeScheduleDays()
        const dict = {}
        if (list && list.length) {
          list.forEach(function (item) {
            dict[item.value] = item
          })
        }
        return dict
      },
      getEmployeeSubTypeDict: function () {
        const list = Utils.getEmployeeSubTypes()
        const dict = {}
        if (list && list.length) {
          list.forEach(function (item) {
            dict[item.value] = item
          })
        }
        return dict
      },
      getEmployees: function () {
        const self = this
        const fields = ['pt_id', 'db_id', 'id', 'name', 'email', 'status', 'mobile_phone']
        const subType = this.hasSubType && this.isEmployee && Utils.hasPermission('employee_trainees_view') ? Utils.getEmployeeSubTypes().find(function (i) {
          return i.type === 'trainee'
        }) : null
        this.$isLoading(true)
        if (this.hasSubType) {
          fields.push('sub_type')
        }
        Api.getEmployees({
          fields: fields,
          sub_type: subType ? subType.value : null
        },function (response) {
          self.$isLoading(false)
          if (response.success) {
            self.employees = self.encodeEmployees(response.data)
            const dict = {}
            dict[Math.abs(axios.defaults.ptId)] = {
              name: 'Admin'
            }
            self.employees.forEach(function (emp) {
              dict[emp.id] = emp
            })
            self.employeesDict = dict
          } else {
            self.$alert(
              response.message,
              '',
              'warning',
              Utils.getAlertOptions()
            )
          }
        })
      },
      encodeEmployees: function (data) {
        const employees = []
        const ids = []
        for (let i = 0; i < data.length; i++) {
          const employeeId = data[i].id
          if (window.cachePhotos['employee-' + employeeId]) {
            data[i].photo = window.cachePhotos['employee-' + employeeId]
          } else {
            data[i].photo = require('@/assets/user.svg')
            ids.push(employeeId)
          }
          employees.push(data[i])
        }
        if (ids.length > 0 && this.showPhotos) {
          this.getEmployeesPhotos(ids)
        }
        return employees
      },
      getEmployeesPhotos: function (ids) {
        const self = this
        const splitIds = Utils.splitArray(ids, 5)
        getPhotos(0)

        function getPhotos (index) {
          if (splitIds[index] && self.$route.path === '/home/employees') {
            Api.getEmployeesPhotos(splitIds[index], function (response) {
              setPhotos(response, splitIds[index])
              getPhotos(index + 1)
            })
          }
        }

        function setPhotos (response, ids) {
          const photos = {}
          if (response.success) {
            for (let i = 0; i < response.data.length; i++) {
              photos[response.data[i].id] = response.data[i].photo
            }
          }
          for (let i = 0; i < self.employees.length; i++) {
            const id = self.employees[i].id
            if (ids.indexOf(id) > -1) {
              const newPhoto = photos[id] ? photos[id] : require('@/assets/user.svg')
              window.cachePhotos['employee-' + id] = newPhoto
              self.employees[i].photo = newPhoto
            }
          }
        }
      },
      activeEmployee (employee) {
        const self = this
        this.$confirm(
          window.strings['want_activate_employee'] + (employee ? (' "' + employee.name + '"') : '') + '?',
          '',
          'success',
          Utils.getAlertOptions(true)
        ).then(() => {
          self.updateUser(employee, self.statusMap.active)
        }).catch(() => { })
      },
      suspendEmployee (employee) {
        const self = this
        this.$confirm(
          window.strings['want_suspend_employee'] + (employee ? (' "' + employee.name + '"') : '') + '?',
          '',
          'warning',
          Utils.getAlertOptions(true, true)
        ).then(() => {
          self.updateUser(employee, self.statusMap.suspended)
        }).catch(() => { })
      },
      blockEmployee(employee) {
        const self = this
        this.$confirm(
          window.strings['want_block_employee'] + (employee ? (' "' + employee.name + '"') : '') + '?',
          '',
          'warning',
          Utils.getAlertOptions(true, true)
        ).then(() => {
          self.updateUser(employee, self.statusMap.blocked)
        }).catch(() => { })
      },
      openNewEmployee () {
        if (this.editPermission) {
          this.setEmployeeData()
          this.editedIndex = -1
          this.dialogEmployee = true
        }
      },
      getEmployeeData: function () {
        const data = {
          type: 2,
        }
        for (let i = 0; i < this.employeeData.length; i++) {
          if (this.employeeData[i].id === 'professional_license') {
            data.notes = JSON.stringify({
              professional_license: this.employeeData[i].value
            })
            continue
          }
          if (this.employeeData[i].id !== 'photos' && !this.employeeData[i].isPermission) {
            if (this.employeeData[i].id === 'email') {
              data[this.employeeData[i].id] = Utils.encodeEmail(this.employeeData[i].value)
              continue
            }
            if (this.employeeData[i].type === 'checkbox') {
              data[this.employeeData[i].id] = this.employeeData[i].value ? 1 : 0
              continue
            }
            data[this.employeeData[i].id] = this.employeeData[i].value
          }
        }
        if (this.editedIndex > -1) {
          data.id = this.employees[this.editedIndex].id
        }
        return data
      },
      getDefaultScheduleData: function () {
        return [{
          weekday: 1,
          start_time_h: '00',
          start_time_m: '00',
          end_time_h: '23',
          end_time_m: '59',
          status: 0,
        }, {
          weekday: 2,
          start_time_h: '00',
          start_time_m: '00',
          end_time_h: '23',
          end_time_m: '59',
          status: 0,
        }, {
          weekday: 3,
          start_time_h: '00',
          start_time_m: '00',
          end_time_h: '23',
          end_time_m: '59',
          status: 0,
        }, {
          weekday: 4,
          start_time_h: '00',
          start_time_m: '00',
          end_time_h: '23',
          end_time_m: '59',
          status: 0,
        }, {
          weekday: 5,
          start_time_h: '00',
          start_time_m: '00',
          end_time_h: '23',
          end_time_m: '59',
          status: 0,
        }, {
          weekday: 6,
          start_time_h: '00',
          start_time_m: '00',
          end_time_h: '23',
          end_time_m: '59',
          status: 0,
        }, {
          weekday: 7,
          start_time_h: '00',
          start_time_m: '00',
          end_time_h: '23',
          end_time_m: '59',
          status: 0,
        }]
      },
      editEmployee: function (employee) {
        const self = this
        if (this.editPermission && (!this.isEmployee || (this.isEmployee && this.user.db_id !== employee.db_id))) {
          this.editedIndex = this.employees.findIndex(function (it) {
            return it.id === employee.id
          })
          this.$isLoading(true)
          Api.getEmployee({
            id: employee.id
          },function (response) {
            if (response.success && response.data && response.data[0]) {
              const item = response.data[0]
              self.getPermissions(item, function () {
                self.getSchedule(item, function () {
                  self.$isLoading(false)
                  self.setEmployeeData(item)
                  self.dialogEmployee = true
                })
              })
            } else {
              self.$isLoading(false)
              self.$alert(
                response.message,
                '',
                'warning',
                Utils.getAlertOptions()
              )
            }
          })
        }
      },
      setEmployeeData: function (data) {
        if (data) {
          for (let i = 0; i < this.employeeData.length; i++) {
            if (this.employeeData[i].id === 'professional_license') {
              try {
                const notes = data.notes ? JSON.parse(data.notes) : {}
                this.employeeData[i].value = notes && notes.professional_license ? notes.professional_license : ''
              } catch { }
              continue
            }

            if (this.employeeData[i].isPermission) {
              for (let p = 0; p < data.permissions.length; p++) {
                if (this.employeeData[i].id === data.permissions[p].name) {
                  if (data.permissions[p].value === '1') {
                    this.employeeData[i].value = true
                  } else {
                    this.employeeData[i].value = false
                  }
                  break
                }
              }
            } else {
              if (data[this.employeeData[i].id] !== undefined) {
                this.employeeData[i].value = data[this.employeeData[i].id]
              }
            }

            if (this.employeeData[i].id === 'email') {
              this.employeeData[i].disabled = true
            }
          }
          this.setScheduleData(data.schedule)
        } else {
          for (let i = 0; i < this.employeeData.length; i++) {
            if (this.employeeData[i].type === 'checkbox') {
              this.employeeData[i].value = false
            } else {
              this.employeeData[i].value = ''
            }

            if (this.employeeData[i].id === 'email') {
              this.employeeData[i].disabled = false
            }
          }
          this.setScheduleData(this.getDefaultScheduleData())
        }
      },
      setScheduleData (src) {
        if (this.showEmployeeSchedule) {
          const scheduleData = []
          for (let s = 0; s < src.length; s++) {
            let startTimeSplit = []
            let endTimeSplit = []
            if (src[s].start_time) {
              startTimeSplit = src[s].start_time.split(':')
            }
            if (src[s].end_time) {
              endTimeSplit = src[s].end_time.split(':')
            }
            scheduleData.push({
              weekday: src[s].weekday,
              start_time_h: startTimeSplit[0],
              start_time_m: startTimeSplit[1],
              end_time_h: endTimeSplit[0],
              end_time_m: endTimeSplit[1],
              status: src[s].status,
            })
          }
          this.scheduleData = scheduleData
        }
      },
      getPermissions (employee, callback) {
        const self = this
        Api.getPermissions(employee.db_id, function (response) {
          if (response.success) {
            employee.permissions = response.data
            if (employee.passwordDecrypted) {
              callback()
            } else {
              Api.decryptPassword(employee.password, function (response) {
                if (response.success) {
                  employee.password = response.data.password
                  employee.passwordDecrypted = true
                  callback()
                } else {
                  self.$isLoading(false)
                  self.$alert(
                    response.message,
                    '',
                    'warning',
                    Utils.getAlertOptions()
                  )
                }
              })
            }
          } else {
            self.$isLoading(false)
              self.$alert(
                response.message,
                '',
                'warning',
                Utils.getAlertOptions()
              )
          }
        })
      },
      getSchedule (employee, callback) {
        const self = this
        if (!this.showEmployeeSchedule) {
          return callback()
        }
        Api.getEmployeeSchedule(employee.db_id, function (response) {
          if (response.success) {
            employee.schedule = response.data
            callback()
          } else {
            self.$isLoading(false)
            self.$alert(
              response.message,
              '',
              'warning',
              Utils.getAlertOptions()
            )
          }
        })
      },
      updateUser (employee, status) {
        const self = this
        Api.updateUser({
          id: employee.id,
          status: status,
        }, function (response) {
          if (response.success) {
            for (let i = 0; i < self.employees.length; i++) {
              if (self.employees[i].id === employee.id) {
                if (response.data.status === self.statusMap.blocked) {
                  self.employees.splice(i, 1)
                } else (
                  self.employees[i].status = response.data.status
                )
                break
              }
            }
          } else {
            self.$alert(
              response.message,
              '',
              'warning',
              Utils.getAlertOptions()
            )
          }
        })
      },
      closeEmployee () {
        this.dialogEmployee = false
        this.$nextTick(() => {
          this.editedIndex = -1
        })
      },
      saveEmployee () {
        const self = this
        const data = this.getEmployeeData()
        this.$isLoading(true)
        if (this.editedIndex < 0) {
          Api.newEmployee(data, function (response) {
            if (response.success) {
              self.savePermissions(response.data.db_id, function (permissions) {
                self.saveSchedule(response.data.db_id, function (schedule) {
                  response.data.permissions = permissions
                  response.data.schedule = schedule
                  response.data.photo = require('@/assets/user.svg')
                  self.employees.push(response.data)
                  self.closeEmployee()
                  self.$isLoading(false)
                })
              })
            } else {
              self.$isLoading(false)
              self.$alert(
                response.message,
                '',
                'warning',
                Utils.getAlertOptions()
              )
            }
          })
        } else {
          Api.updateUser(data, function (response) {
            if (response.success) {
              self.savePermissions(response.data.db_id, function (permissions) {
                self.saveSchedule(response.data.db_id, function (schedule) {
                  response.data.permissions = permissions
                  response.data.schedule = schedule
                  if (!response.data.photo) {
                    response.data.photo = require('@/assets/user.svg')
                  }
                  self.$set(self.employees, self.editedIndex, response.data)
                  self.closeEmployee()
                  self.$isLoading(false)
                })
              })
            } else {
              self.$isLoading(false)
              self.$alert(
                response.message,
                '',
                'warning',
                Utils.getAlertOptions()
              )
            }
          })
        }
      },
      savePermissions (id, callback) {
        const permissions = []
        for (let i = 0; i < this.permissions.length; i++) {
          permissions.push({
            name: this.permissions[i].id,
            value: '0',
            type: 0,
          })
        }
        const data = {}
        for (let i = 0; i < this.employeeData.length; i++) {
          if (this.employeeData[i].isPermission) {
            data[this.employeeData[i].id] = this.employeeData[i].value
          }
        }
        for (let i = 0; i < permissions.length; i++) {
          if (data[permissions[i].name]) {
            permissions[i].value = '1'
          } else {
            permissions[i].value = '0'
          }
        }
        Api.updatePermissions({
          id: id,
          permissions: permissions,
        }, function (response) {
          if (response.success) {
            callback(response.data)
          } else {
            self.$isLoading(false)
            self.$alert(
              response.message,
              '',
              'warning',
              Utils.getAlertOptions()
            )
          }
        })
      },
      saveSchedule (id, callback) {
        const self = this
        if (!this.showEmployeeSchedule) {
          return callback(null)
        }
        const data = JSON.parse(JSON.stringify(this.scheduleData))
        for (let s = 0; s < data.length; s++) {
          if (data[s].start_time_h) {
            data[s].start_time = data[s].start_time_h + ':' + data[s].start_time_m
            delete data[s].start_time_h
            delete data[s].start_time_m
          }
          if (data[s].end_time_h) {
            data[s].end_time = data[s].end_time_h + ':' + data[s].end_time_m
            delete data[s].end_time_h
            delete data[s].end_time_m
          }
        }
        Api.updateEmployeeSchedule({
          id: id,
          schedule: data,
        }, function (response) {
          if (response.success) {
            callback(response.data)
          } else {
            self.$isLoading(false)
            self.$alert(
              response.message,
              '',
              'warning',
              Utils.getAlertOptions()
            )
          }
        })
      },
      showHistoric (item) {
        this.historicTab = 0
        this.historicDataSearch = ''
        this.resetHistoryTabSelect('historic')
        this.resetHistoryTabSelect('chat')
        this.historicData = {
          user: item,
          dates: [Utils.getFormatDate(new Date(Date.now() - 86400000)), Utils.getFormatDate()],
          date: Utils.getFormatDate(),
          dashboard: [],
          chatData: [],
          chatLogs: [],
          chat: {},
          dashboardLoading: true,
          chatLoading: true,
        }
        this.applyDashboardHistoricDates()
        this.dialogHistoric = true
      },
      applyDashboardHistoricDates () {
        const self = this
        this.historicData.dashboardLoading = true
        this.datepickerHistoricMenu = false
        if (this.historicData.user) {
          Api.getWorkLog({
            id: this.historicData.user.db_id,
            startDate: this.historicData.dates[0],
            endDate: this.historicData.dates[1],
          }, function (response) {
            self.historicData.dashboardLoading = false
            if (response.success) {
              encodeData(response.data)
            } else {
              self.$alert(
                response.message,
                '',
                'warning',
                Utils.getAlertOptions()
              )
            }
          })
        } else {
          Api.getWorkLogSearch({
            startDate: this.historicData.dates[0],
            endDate: this.historicData.dates[1],
          }, function (response) {
            self.historicData.dashboardLoading = false
            if (response.success) {
              encodeData(response.data)
            } else {
              self.$alert(
                response.message,
                '',
                'warning',
                Utils.getAlertOptions()
              )
            }
          })
        }

        function encodeData(data) {
          for (let i = 0; i < data.length; i++) {
            data[i].date = Utils.getFormatDateHour(data[i].date, true)
            data[i].notes = data[i].description
            data[i].description = self.historicCategoryDict[data[i].category] ? self.historicCategoryDict[data[i].category].action : window.strings['n_a']
            if (!data[i].action) {
              data[i].action = data[i].client_id
            }
          }
          self.historicData.dashboard = data.sort(function (a, b) {
            const keyA = new Date(a.date)
            const keyB = new Date(b.date)
            if (keyA < keyB) return -1
            if (keyA > keyB) return 1
            return 0
          })
          self.resetHistoryTabSelect('historic', true)
        }
      },
      openClient: function (item) {
        const self = this
        this.$isLoading(true)
        Api.getUserWithId({
          id: item.action ? item.action : (item.id ? item.id : item)
        }, function (response) {
          self.$isLoading(false)
          if (response.success && response.data[0]) {
            if (response.data[0].status === self.statusMap.blocked) {
              return self.$alert(
                window.strings['blocked_client'],
                '',
                'warning',
                Utils.getAlertOptions()
              )
            }
            const client = Utils.encodeClient(response.data[0])
            Utils.setStorage('client', client)
            window.showPaydayAlert = true
            window.clientPageCacheList = null
            self.$router.navigate.push({ path: '/home/client', query: { tab: 'details' } })
          } else {
            self.$alert(
              response.message,
              '',
              'warning',
              Utils.getAlertOptions()
            )
          }
        })
      },
      applyChatHistoricDate () {
        const self = this
        this.historicData.chatLoading = true
        this.datepickerHistoricMenu = false
        const tab = this.getHistoryTabSelectFilter('chat')
        if (tab === 'list') {
          if (this.historicData.user) {
            ChatApi.getChatMessagesWorkLog({
              employeeId: this.historicData.user.id,
              employeePtId: this.historicData.user.pt_id,
              date: this.historicData.date,
            }, function (response) {
              if (response.success) {
                self.getClientsByChatsIds([...new Set(response.data.map(x => x.client_id))], function (clients) {
                  self.historicData.chatLoading = false
                  const chatLogs = []
                  response.data.forEach(function (item) {
                    const categoryDescription = self.chatCategoryDict[item.category] ? (window.strings[self.chatCategoryDict[item.category].label] ? window.strings[self.chatCategoryDict[item.category].label] : self.chatCategoryDict[item.category].label) :  window.strings['message_sent_to_client']
                    chatLogs.push({
                      date: Utils.getFormatDateHour(item.date_utc, true),
                      description: categoryDescription + (clients[item.client_id] ? (' ' + clients[item.client_id].name) : ''),
                      message_id: !self.chatCategoryDict[item.category] || (self.chatCategoryDict[item.category] && !self.chatCategoryDict[item.category].hide_chat) ? item.message_id : null,
                      chat_id: item.chat_id,
                      client_id: item.client_id,
                      action: clients[item.client_id] ? clients[item.client_id].id : null,
                      hide_chat: self.chatCategoryDict[item.category] && self.chatCategoryDict[item.category].hide_chat ? true : false,
                    })
                  })
                  self.historicData.chatLogs = chatLogs
                })
              } else {
                self.$alert(
                  response.message,
                  '',
                  'warning',
                  Utils.getAlertOptions()
                )
              }
            })
          } else {
            ChatApi.getChatWorkLogSearch({
              startDate: this.historicData.dates[0],
              endDate: this.historicData.dates[1]
            }, function (response) {
              if (response.success) {
                self.getClientsByChatsIds([...new Set(response.data.map(x => x.client_id))], function (clients) {
                  self.historicData.chatLoading = false
                  const chatLogs = []
                  response.data.forEach(function (item) {
                    const categoryDescription = self.chatCategoryDict[item.category] ? (window.strings[self.chatCategoryDict[item.category].label] ? window.strings[self.chatCategoryDict[item.category].label] : self.chatCategoryDict[item.category].label) :  window.strings['message_sent_to_client']
                    chatLogs.push({
                      date: item.date_utc ? Utils.getFormatDateHour(item.date_utc, true) : Utils.getFormatDateHour(item.date, true),
                      description: categoryDescription + (clients[item.client_id] ? (' ' + clients[item.client_id].name) : ''),
                      message_id: !self.chatCategoryDict[item.category] || (self.chatCategoryDict[item.category] && !self.chatCategoryDict[item.category].hide_chat) ? item.message_id : null,
                      chat_id: item.chat_id,
                      client_id: item.client_id,
                      action: clients[item.client_id] ? clients[item.client_id].id : null,
                      employee_name: self.employeesDict[item.employee_id] ? self.employeesDict[item.employee_id].name : item.employee_id,
                      hide_chat: self.chatCategoryDict[item.category] && self.chatCategoryDict[item.category].hide_chat ? true : false,
                    })
                  })
                  self.historicData.chatLogs = chatLogs
                })
              } else {
                self.$alert(
                  response.message,
                  '',
                  'warning',
                  Utils.getAlertOptions()
                )
              }
            })
          }
        } else {
          ChatApi.getChatWorkLog({
            employeeId: this.historicData.user ? this.historicData.user.id : null,
            employeePtId: this.historicData.user ? this.historicData.user.pt_id : null,
            date: this.historicData.date,
          }, function (response) {
            self.historicData.chatLoading = false
            if (response.success) {
              self.historicData.chatData = response.data
              self.historicData.chat = self.getHistoricChart()
            } else {
              self.$alert(
                response.message,
                '',
                'warning',
                Utils.getAlertOptions()
              )
            }
          })
        }
      },
      getClientsByChatsIds (chatsIds, callback) {
        const self = this
        if (chatsIds.length) {
          if (this.clientsByChatsIdsCache && this.clientsByChatsIdsCache[JSON.stringify(chatsIds)]) {
            return callback(this.clientsByChatsIdsCache[JSON.stringify(chatsIds)])
          }
          Api.getClientsByChatsIds({
            chatsIds: chatsIds,
            fields: ['id', 'name', 'chat_id']
          }, function (response) {
            if (response.success) {
              const clients = {}
              response.data.forEach(function (cl) {
                clients[cl.chat_id] = cl
              })
              self.clientsByChatsIdsCache = self.clientsByChatsIdsCache ? self.clientsByChatsIdsCache : {}
              self.clientsByChatsIdsCache[JSON.stringify(chatsIds)] = clients
              callback(clients)
            } else {
              callback({})
              self.$alert(
                response.message,
                '',
                'warning',
                Utils.getAlertOptions()
              )
            }
          })
        } else {
          callback({})
        }
      },
      getHistoricChart () {
        const data = this.historicData.chatData
        const filterValue = this.getHistoryTabSelectFilter('chat')
        const filter = this.historicTabs.find(function (tab) {
          return tab.id === 'chat'
        }).select.items.find(function (it) {
          return it.value === filterValue
        })
        const legend = JSON.parse(JSON.stringify(filter.keys))
        for (let i = 0; i < legend.length; i++) {
          legend[i].active = true
        }
        const chart = {
          type: 'Line',
          horizontal: false,
          title: filter.label,
          color: 'blue-grey darken-3',
          labels: [],
          series: [],
          size: '400',
          sizelimit: '400',
          legend: legend,
          class: null
        }
        const diffHoursUtc = moment().utcOffset() / 60
        const labelsIndex = {}
        const labels = []
        const dataHours = []
        let max = 0

        for (let i = 0; i < 24; i++) {
          const hour = i.toString()
          labels.push({
            text: i + 'h00 - ' + i + 'h30',
            hour: hour,
            step: 0,
            series: []
          })
          labels.push({
            text: i + 'h30 - ' + (i + 1 > 23 ? '0' : i + 1) + 'h00',
            hour: hour,
            step: 1,
            series: []
          })
          dataHours.push({})
          dataHours.push({})
          labelsIndex[hour] = labels.length - 1
        }

        data.forEach(function (item) {
          const hour = (item.hour_utc + diffHoursUtc).toString()
          filter.keys.forEach(function (key) {
            let hourtStep0 = null
            let hourtStep1 = null
            if (item.half_hour > 0 || item.half_hour === 0) {
              if (item.half_hour > 0) {
                hourtStep1 += item[key.value]
              }
              if (item.half_hour < 1) {
                hourtStep0 += item[key.value]
              }
            } else {
              hourtStep0 += item[key.value]
              hourtStep1 = 0
            }
            const index = labelsIndex[hour] - 1
            if (!dataHours[index]) {
              dataHours[index] = {}
            }
            if (!dataHours[index][key.value]) {
              dataHours[index][key.value] = 0
            }
            if (!dataHours[index + 1][key.value]) {
              dataHours[index + 1][key.value] = 0
            }
            dataHours[index][key.value] += hourtStep0
            dataHours[index + 1][key.value] += hourtStep1
          })
        })

        let meta = ''
        for (let l = 0; l < labels.length; l++) {
          chart.labels.push('')
          meta = labels[l].text 
          filter.keys.forEach(function (key) {
            const index = labelsIndex[labels[l].hour] - 1
            const value = dataHours[index + labels[l].step] && dataHours[index + labels[l].step][key.value] ? dataHours[index + labels[l].step][key.value] : 0
            meta += '\n' + key.label + ': ' + value + (key.unit ? (' ' + key.unit) : '')
            max = value > max ? value : max
          })
          filter.keys.forEach(function (key, serieIndex) {
            const index = labelsIndex[labels[l].hour] - 1
            const value = dataHours[index + labels[l].step] && dataHours[index + labels[l].step][key.value] ? dataHours[index + labels[l].step][key.value] : 0
            if (!chart.series[serieIndex]) {
              chart.series[serieIndex] = []
            }
            chart.series[serieIndex].push({
              meta: meta,
              value: value
            })
          })
        }

        chart.options = {
          lineSmooth: this.$chartist.Interpolation.cardinal({
            tension: 0,
          }),
          low: 0,
          high: max,
          chartPadding: {
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
          },
          plugins: [
            this.$chartist.plugins.tooltip({
              appendToBody: true,
            })
          ],
          axisY: {
            onlyInteger: true
          }
        }

        return chart
      },
      filterHistoricChatLegend (item) {
        item.active = !item.active
        const activeIndexs = []
        const chartClasses = []
        this.historicData.chat.legend.forEach(function (it, index) {
          if (it.active) {
            activeIndexs.push(index)
          } else {
            chartClasses.push('chart-hide-serie-' + index)
          }
        })
        this.historicData.chat.class = chartClasses
        let max = 0
        for (let i = 0; i < this.historicData.chat.series.length; i++) {
          if (activeIndexs.indexOf(i) > -1) {
            if (this.historicData.chat.series[i].length) {
              const highestValue = this.historicData.chat.series[i].reduce((highest, current) => {
                if (current.value > highest.value) {
                  return current;
                } else {
                  return highest;
                }
              }, this.historicData.chat.series[i][0])
              max = highestValue.value > max ? highestValue.value : max
            }
          }
        }
        this.historicData.chat.options.high = max
      },
      closeHistoric () {
        this.dialogHistoric = false
        this.historicData = null
      },
      resetHistoryTabSelect (tab, refreshLabels) {
        for (let i = 0; i < this.historicTabs.length; i++) {
          if (this.historicTabs[i].id === tab) {
            if (!this.historicTabs[i].averageHours) {
              this.historicTabs[i].averageHours = this.historicTabs[i].defaultAverageHours
            }
            if (refreshLabels) {
              const items = JSON.parse(JSON.stringify(this.historicTabs[i].select.items))
              for (let s = 0; s < items.length; s++) {
                const item =  items[s]
                const total = this.historicData.dashboard.filter(function (l) {
                  return !item.value || l.category === item.value
                }).length
                item.label = item.label.replace(/\(\d+\)/g, '').trim() + ' (' + total.toString() + ')'
              }
              this.historicTabs[i].select.items = items
            } else {
              this.historicTabs[i].select.value = this.historicTabs[i].select.defaultValue
            }
            break
          }
        }
      },
      getHistoryTabSelectFilter (tab) {
        for (let i = 0; i < this.historicTabs.length; i++) {
          if (this.historicTabs[i].id === tab) {
            return this.historicTabs[i].select.value
          }
        }
      },
      showAssignClients (item) {
        const self = this
        this.assignClientsData = {
          name: item.name,
          logs: []
        }
        this.$isLoading(true)
        Api.getEmployeeAssignClients(item.db_id, function (response) {
          self.$isLoading(false)
          if (response.success) {
            self.assignClientsData.logs = response.data
            self.dialogAssignClients = true
          } else {
            self.$alert(
              response.message,
              '',
              'warning',
              Utils.getAlertOptions()
            )
          }
        })
      },
      closeAssignClients () {
        this.dialogAssignClients = false
        this.assignClientsData = null
      },
      openSearchClientLogsDialog () {
        this.searchClientLogsData = {
          clientSearchValue: '',
          logsSearchValue: '',
          client: null,
          dates: [Utils.getFormatDate(new Date(Date.now() - 86400000)), Utils.getFormatDate()],
          logs: [],
          loading: false,
        }
        this.dialogSearchClientLogs = true
      },
      closeSearchClientLogsDialog () {
        this.dialogSearchClientLogs = false
        this.searchClientLogsData = null
      },
      searchClientById () {
        const self = this
        this.searchClientLogsData.loading = true
        Api.getUserWithId({
          id: parseInt(this.searchClientLogsData.clientSearchValue)
        }, function (response) {
          if (response.success) {
            if (response.data[0]) {
              self.searchClientLogsData.client = response.data[0]
              self.applySearchClientLogs()
            } else {
              self.searchClientLogsData.loading = false
              self.$alert(
                window.strings['client_not_found'],
                '',
                'warning',
                Utils.getAlertOptions()
              )
            }
          } else {
            self.searchClientLogsData.loading = false
            self.$alert(
              response.message,
              '',
              'warning',
              Utils.getAlertOptions()
            )
          }
        })
      },
      applySearchClientLogs () {
        const self = this
        this.datepickerSearchClientLogsMenu = false
        if (!this.searchClientLogsData.client) {
          return false
        }
        this.searchClientLogsData.loading = true
        Api.getWorkLogSearch({
          clientId: this.searchClientLogsData.client.id,
          startDate: this.searchClientLogsData.dates[0],
          endDate: this.searchClientLogsData.dates[1]
        }, function (response) {
          if (response.success) {
            self.getClientChatLogs(function (chatLogs) {
              const logs = []
              response.data.forEach(function (item) {
                logs.push({
                  date: Utils.getFormatDateHour(item.date, true),
                  category: item.category,
                  description: self.historicCategoryDict[item.category] ? self.historicCategoryDict[item.category].action : window.strings['n_a'],
                  employee_name: self.employeesDict[item.employee_id] ? self.employeesDict[item.employee_id].name : item.employee_id,
                  notes: item.description
                })
              })
              self.searchClientLogsData.loading = false
              self.searchClientLogsData.logs = logs.concat(chatLogs)
            })
          } else {
            self.searchClientLogsData.loading = false
            self.$alert(
              response.message,
              '',
              'warning',
              Utils.getAlertOptions()
            )
          }
        })
      },
      getClientChatLogs (callback) {
        const self = this
        if (this.searchClientLogsData.client && this.searchClientLogsData.client.chat_id) {
          ChatApi.getChatWorkLogSearch({
            clientId: this.searchClientLogsData.client.chat_id,
            startDate: this.searchClientLogsData.dates[0],
            endDate: this.searchClientLogsData.dates[1]
          }, function (response) {
            if (response.success) {
              const logs = []
              response.data.forEach(function (item) {
                const categoryDescription = self.chatCategoryDict[item.category] ? (window.strings[self.chatCategoryDict[item.category].label] ? window.strings[self.chatCategoryDict[item.category].label] : self.chatCategoryDict[item.category].label) :  window.strings['message_sent_to_client']
                logs.push({
                  date: Utils.getFormatDateHour(item.date_utc, true),
                  description: categoryDescription,
                  message_id: !self.chatCategoryDict[item.category] || (self.chatCategoryDict[item.category] && !self.chatCategoryDict[item.category].hide_chat) ? item.message_id : null,
                  chat_id: item.chat_id,
                  client_id: item.client_id,
                  employee_name: self.employeesDict[item.employee_id] ? self.employeesDict[item.employee_id].name : item.employee_id,
                  hide_chat: self.chatCategoryDict[item.category] && self.chatCategoryDict[item.category].hide_chat ? true : false,
                })
              })
              callback(logs)
            } else {
              self.searchClientLogsData.loading = false
              self.$alert(
                response.message,
                '',
                'warning',
                Utils.getAlertOptions()
              )
            }
          })
        } else {
          callback([])
        }
      },
      showMessage (item) {
        const self = this
        if (item.message_id) {
          this.$isLoading(true)
          ChatApi.getChatMessage({
            chat_id: item.chat_id,
            id: item.message_id
          }, function (response) {
            if (response.success) {
              if (response.data[0]) {
                if (response.data[0].type === self.messageMediaTypes.video) {
                  getMessageVideo(response.data[0].media, function (video) {
                    self.$isLoading(false)
                    self.dialogMessageData = {
                      text: response.data[0].message,
                      audio: null,
                      image: null,
                      video: video,
                    }
                    self.dialogMessage = true
                  })
                } else {
                  self.$isLoading(false)
                  self.dialogMessageData = {
                    text: response.data[0].message,
                    audio: response.data[0].type === self.messageMediaTypes.audio ? response.data[0].media : null,
                    image: response.data[0].type === self.messageMediaTypes.image ? response.data[0].media : null,
                    video: response.data[0].type === self.messageMediaTypes.video ? response.data[0].media : null,
                  }
                  self.dialogMessage = true
                }
              } else {
                self.$isLoading(false)
                self.$alert(
                  window.strings['message_not_found'],
                  '',
                  'warning',
                  Utils.getAlertOptions()
                )
              }
            } else {
              self.$isLoading(false)
              self.$alert(
                response.message,
                '',
                'warning',
                Utils.getAlertOptions()
              )
            }
          })

          function getMessageVideo(id, callback) {
            if (id) {
              StorageApi.getMessageVideo(id, function (response) {
                if (response.success) {
                  callback(response.data && response.data[0] && response.data[0].file ? response.data[0].file : null)
                } else {
                  callback(null)
                  self.$alert(
                    response.message,
                    '',
                    'warning',
                    Utils.getAlertOptions()
                  )
                }
              })
            }
          }
        }
      },
      closeMessage: function () {
        this.dialogMessage = false
        this.dialogMessageData = null
      },
      openClientChat: function (item) {
        const self = this
        if (item && !item.hide_chat) {
          this.$isLoading(true)
          Api.getUserWithId({
            id: item.action ? item.action : item
          }, function (response) {
            self.$isLoading(false)
            if (response.success && response.data[0]) {
              const client = response.data[0]
              if (client.status === self.statusMap.blocked) {
                return self.$alert(
                  window.strings['blocked_client'],
                  '',
                  'warning',
                  Utils.getAlertOptions()
                )
              }
              Utils.openClientChat({
                scope: self,
                client: client
              })
            } else {
              self.$alert(
                response.message,
                '',
                'warning',
                Utils.getAlertOptions()
              )
            }
          })
        }
      },
      showInformation: function (text) {
        this.dialogInformationValue = text
        this.dialogInformation = true
      },
      closeInformation: function () {
        this.dialogInformation = false
        this.dialogInformationValue = null
      },
      copyTextToClipboard: function (text) {
        if (navigator && navigator.clipboard) {
          const textarea = document.createElement('textarea')
          textarea.value = text
          document.body.appendChild(textarea)
          textarea.select()
          document.execCommand('copy')
          document.body.removeChild(textarea)
        }
      },
      openEmployeeClients: function (item) {
        const self = this
        this.clientsTableMenu = false
        this.$isLoading(true)
        Api.getEmployeeClients({
          id: item ? item.id : this.dialogClientsEmployee.id,
          start_date: this.clientsTableDates[0],
          end_date: this.clientsTableDates[1]
        },function (response) {
          self.$isLoading(false)
          if (response.success) {
            self.clientsTableItems = response.data
            if (item) {
              self.dialogClientsEmployee = item
              self.dialogClients = true
            }
          } else {
            self.$alert(
              response.message,
              '',
              'warning',
              Utils.getAlertOptions()
            )
          }
        })
      },
      closeEmployeeClients: function () {
        this.dialogClients = false
        this.dialogClientsEmployee = null
        this.clientsTableItems = []
      },
      selectAllPermissions: function (enable) {
        for (let i = 0; i < this.employeeData.length; i++) {
          if (this.employeeData[i].isPermission) {
            this.employeeData[i].value = enable ? true : false
          }
        }
      },
      usePermissions: function () {
        this.selectEmployeePermissionsSearchValue = ''
        this.dialogSelectEmployeePermissions = true
      },
      selectEmployeePermissions: function (employee) {
        const self = this
        this.$isLoading(true)
        Api.getEmployee({
          id: employee.id
        },function (response) {
          if (response.success && response.data && response.data[0]) {
            const item = response.data[0]
            item.passwordDecrypted = true
            self.getPermissions(item, function () {
              const values = item.permissions.reduce((obj, item) => {
                obj[item.name] = item.value
                return obj
              }, {})
              for (let i = 0; i < self.employeeData.length; i++) {
                if (self.employeeData[i].isPermission) {
                  self.employeeData[i].value = values[self.employeeData[i].id] === '1' ? true : false
                }
              }
              self.$isLoading(false)
              self.dialogSelectEmployeePermissions = false
            })
          } else {
            self.$isLoading(false)
            self.$alert(
              response.message,
              '',
              'warning',
              Utils.getAlertOptions()
            )
          }
        })
      },
    },
  }
</script>
